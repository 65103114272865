function Switch({ checked, onChecked }) {
  return (
    <div className="flex items-center justify-center">
      <input
        className="switch appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top  bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm checked:bg-green-500"
        type="checkbox"
        role="switch"
        checked={checked}
        onChange={onChecked}
      />
    </div>
  );
}
export default Switch;
