import { useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
} from "react-query";
import axios from "axios";

import Switch from "./components/Switch";
import Form from "./components/Form";
const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || "";
const queryClient = new QueryClient();
const apiClient = axios.create({
  baseURL: `${API_SERVER_URL}/api`,
  headers: {
    "Content-type": "application/json",
  },
});

function truncate(str, length = 15) {
  if (str.length <= length) {
    return str;
  }
  return str.slice(0, length) + "...";
}

function App() {
  const [loadedConfigs, setLoadedConfigs] = useState([]);
  const [activeConfig, setActiveConfig] = useState();
  const [authParams, setAuthParams] = useState();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const websiteid = urlParams.get("website_id");
    if (!token || !websiteid) {
      // window.location.href = "https://dripmanager.com";
    }
    setAuthParams({ websiteid, token });
  }, []);

  // Query to fetch the workflows
  useQuery(
    "query-workflows",
    async () => {
      return await apiClient.get("/workflows", { headers: authParams });
    },
    {
      onSuccess: (res) => {
        setLoadedConfigs(res.data ? res.data : []);
      },
      onError: (err) => {
        console.log(err);
        // window.location.href = "https://dripmanager.com";
      },
      enabled: !!(authParams && authParams?.websiteid),
    }
  );

  // Mutation to save the workflows
  // TODO: This needs authenticating
  const { mutateAsync, isLoading: isSaving } = useMutation(
    "mutate-workflows",
    async (workflows) => {
      return await apiClient.post("/workflows", workflows, {
        headers: authParams,
      });
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ["query-workflows"] });
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const handleOnNewDripClick = () => {
    setActiveConfig({
      triggeredBy: "segments",
      triggeredByValues: [],
      title: "",
      steps: [],
      disabled: true,
    });
  };

  const handleEnableSequence = (id) => {
    const newConfigs = [...loadedConfigs].map((config) => {
      if (config.id === id) {
        config.disabled = false;
      }
      return config;
    });
    mutateAsync(newConfigs);
  };

  const handleDisableSequence = (id) => {
    const newConfigs = [...loadedConfigs].map((config) => {
      if (config.id === id) {
        config.disabled = true;
      }
      return config;
    });
    mutateAsync(newConfigs);
  };

  const handleOnSaveSequence = (newConfig) => {
    // SAVE TO THE DATABASE

    let mapped;
    // Then update the loadedConfigs
    const newConfigs =
      loadedConfigs && Array.isArray(loadedConfigs)
        ? JSON.parse(JSON.stringify(loadedConfigs))
        : [];
    const { id } = newConfig;

    if (!loadedConfigs.find((o) => o.id === id)) {
      newConfigs.push(newConfig);
      setLoadedConfigs(newConfigs);
      mapped = newConfigs;
    } else {
      mapped = newConfigs.map((c) => {
        if (c.id === id) {
          return newConfig;
        }
        return c;
      });
    }

    mutateAsync(mapped).then(() => {
      setActiveConfig(mapped.find((o) => o.id === id));
    });
  };

  const handleOnDeleteSequence = (id) => {
    // SAVE TO THE DATABASE

    // Then update the loadedConfigs
    const newConfigs = JSON.parse(JSON.stringify(loadedConfigs));
    const removed = newConfigs.filter((o) => o.id !== id);
    mutateAsync(removed);
    setActiveConfig();
  };

  const enabledConfigs =
    loadedConfigs && Array.isArray(loadedConfigs)
      ? loadedConfigs.filter((o) => o.disabled === false)
      : [];
  const disabledConfigs =
    loadedConfigs && Array.isArray(loadedConfigs)
      ? loadedConfigs.filter((o) => o.disabled === true)
      : [];

  return (
    <div className="app flex h-full text-sm">
      <aside className="aside bg-white border-r border-slate-200 h-full flex flex-col justify-between">
        <nav className="overflow-y-auto">
          {enabledConfigs.length > 0 && (
            <>
              <h3 className="text-xs text-center tracking-wide p-2 pt-6 border-b border-slate-200 text-green-600">
                ENABLED SEQUENCES.
              </h3>
              <ul>
                {enabledConfigs.map((config) => (
                  <li
                    key={config.id}
                    className={`border-b text-left p-3 font-medium flex justify-between items-start space-x-2 ${
                      activeConfig?.id === config.id ? "bg-custom-bg" : ""
                    }`}
                  >
                    <button
                      className="flex-1 text-left"
                      onClick={(event) => {
                        setActiveConfig(config);
                      }}
                    >
                      {truncate(config.title)}
                    </button>
                    <Switch
                      checked={true}
                      onChecked={(event) => {
                        event.preventDefault();
                        handleDisableSequence(config.id);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
          {disabledConfigs.length > 0 && (
            <>
              <h3 className="text-xs text-center tracking-wide p-2 pt-6 border-b border-slate-200 text-gray-400">
                DISABLED SEQUENCES
              </h3>

              <ul>
                {disabledConfigs.map((config) => (
                  <li
                    key={config.id}
                    className={`border-b text-left p-3 font-medium flex justify-between items-start space-x-2 ${
                      activeConfig?.id === config.id ? "bg-custom-bg" : ""
                    }`}
                  >
                    <button
                      className="flex-1 text-left"
                      onClick={(event) => {
                        setActiveConfig(config);
                      }}
                    >
                      {truncate(config.title)}
                    </button>
                    <Switch
                      checked={false}
                      onChecked={(event) => {
                        event.preventDefault();
                        handleEnableSequence(config.id);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </nav>
        <div className="p-2 border-t border-slate-200">
          <button
            disabled={isSaving}
            onClick={handleOnNewDripClick}
            className="text-white text-xs transition-colors font-semibold bg-slate-800 hover:bg-slate-700 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400 flex space-x-2 justify-center rounded items-center py-3 px-4 w-full"
          >
            <PlusCircleIcon className="w-4 h-4 " />{" "}
            <span>New Drip Sequence</span>
          </button>
        </div>
      </aside>
      {activeConfig && (
        <Form
          isSaving={isSaving}
          initialConfig={activeConfig}
          onSave={handleOnSaveSequence}
          onDelete={handleOnDeleteSequence}
        />
      )}
    </div>
  );
}

const AppWithQuery = () => (
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

export default AppWithQuery;
