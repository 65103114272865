import { useEffect, useRef, useState } from "react";
import {
  CheckCircleIcon,
  ClockIcon,
  FireIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { v4 } from "uuid";
import Loader from "./Loader";

const MAX_STEPS = 10;

function TriggeredByInput({ value, onChange }) {
  return (
    <div className="mb-4 p-4">
      <label
        htmlFor="event"
        className="block text-sm font-medium text-gray-700"
      >
        Your sequence will begin when a customer is assigned a segment named:
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type="text"
          name="event"
          required={true}
          className="form-input block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-slate-300"
          placeholder="e.g. business"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

function EventNameInput({ value, onChange }) {
  return (
    <div className="mb-4 p-4">
      <label
        htmlFor="event"
        className="block text-sm font-medium text-gray-700"
      >
        Event to be triggered
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
          <FireIcon className="w-5 h-5 text-slate-300" />
        </div>
        <input
          type="text"
          name="event"
          required={true}
          className="form-input block w-full rounded-md border-gray-300 pl-8 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-slate-300"
          placeholder="drip:send-day-1-email"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

// function getMaxMinutes(unit) {
//   switch (unit) {
//     case 'weeks':
//       return 4;
//     case 'days':
//       return 4 * 7;
//     case 'hours':
//       return 4 * 7 * 24;
//     case 'minutes':
//     default:
//       return 4 * 7 * 24 * 60;
//   }
// }

function WhenInput({ value, unit = "hours", onChange, onUnitChange }) {
  return (
    <div className="border-t p-4">
      <label
        htmlFor="value"
        className="block text-sm font-medium text-gray-700"
      >
        When should this be sent
      </label>
      <div className="relative mt-1 rounded-md shadow-sm max-w-[200px]">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
          <ClockIcon className="w-5 h-5 text-slate-300" />
        </div>
        <input
          type="number"
          maxLength={5}
          name="value"
          required={true}
          max={60}
          min={1}
          className="form-input block w-full rounded-md border-gray-300 pl-8 pr-32 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-slate-300"
          placeholder="24"
          value={value}
          onChange={onChange}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="unit" className="sr-only">
            Unit
          </label>
          <select
            defaultValue={unit}
            name="unit"
            required={true}
            className="form-select h-full rounded-r-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={onUnitChange}
          >
            <option value="minutes">Minutes</option>
            <option value="hours">Hours</option>
            <option value="days">Days</option>
            <option value="weeks">Weeks</option>
          </select>
        </div>
      </div>
    </div>
  );
}

function Panel({ children }) {
  return (
    <div className="bg-white shadow-md shadow-slate-200 w-full mt-2 mb-6 border border-slate-200 rounded-sm animate-fade-in-up">
      {children}
    </div>
  );
}

function Step({
  id,
  number,
  isLast,
  eventName,
  onEventNameChanged,
  waitValue,
  onWaitValueChanged,
  waitUnit,
  onWaitUnitChanged,
  onAddStep,
  onDuplicateStep,
  onDeleteStep,
  nextStepAvailable,
  duplicateAvailable,
}) {
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHasRendered(true);
    }, 600);
  }, []);
  return (
    <div className="flex">
      <div className={`relative ${!isLast ? "step-progress" : ""}`}>
        <CheckCircleIcon className={`absolute w-6 h-6 text-indigo-400`} />
        <CheckCircleIcon
          className={`w-6 h-6 text-indigo-400 ${
            hasRendered ? "" : "animate-ping"
          }`}
        />
      </div>
      <div className="pl-2 w-full">
        <div className="flex justify-between">
          <h2 className="text-base font-medium">{`Step ${number}`}</h2>
          <div className="flex space-x-8">
            {duplicateAvailable && (
              <button
                className="text-blue-400 hover:text-blue-600 transition-colors text-xs font-semibold"
                onClick={(event) => {
                  onDuplicateStep(id);
                }}
              >
                Duplicate
              </button>
            )}
            <button
              className="text-red-400 hover:text-red-600 transition-colors text-xs font-semibold"
              onClick={(event) => {
                onDeleteStep(id);
              }}
            >
              Delete
            </button>
          </div>
        </div>
        <Panel>
          <EventNameInput
            value={eventName}
            onChange={(event) => {
              onEventNameChanged(id, event.target.value);
            }}
          />
          <WhenInput
            label="Event to be triggered"
            value={waitValue}
            unit={waitUnit}
            onChange={(event) => {
              if (
                typeof event.target.value === "number" &&
                event.target.value > 0
              ) {
                onWaitValueChanged(id, event.target.value);
                return;
              }
              const numberAsString = event?.target?.value.replace(
                /[^\d]+/g,
                ""
              );
              if (numberAsString) {
                const numberValue = parseInt(numberAsString);
                if (numberValue > 0) {
                  onWaitValueChanged(id, numberValue);
                } else {
                  onWaitValueChanged(id, "");
                }
                return;
              }
              onWaitValueChanged(id, "");
            }}
            onUnitChange={(event) => {
              onWaitUnitChanged(id, event.target.value);
            }}
          />
        </Panel>

        {isLast && (
          <div className="h-12 group">
            <div
              className={`${
                !isLast ? "opacity-0 transition group-hover:opacity-100" : ""
              } flex justify-center`}
            >
              {nextStepAvailable ? (
                <button
                  onClick={() => {
                    onAddStep(id);
                  }}
                  className="text-white transition text-xs font-semibold bg-slate-800 hover:bg-slate-700 flex space-x-2 justify-center rounded items-center py-3 px-4"
                >
                  <PlusCircleIcon className="w-4 h-4 " />{" "}
                  <span>Add a step</span>
                </button>
              ) : (
                <p className="font-semibold text-xs text-red-400">
                  You've reached a maximum of {MAX_STEPS} steps
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const isValidData = (data) => {
  if (!data.title || data.title.trim() === "") {
    return false;
  }
  if (!data.triggeredByValues || data.triggeredByValues.length < 1) {
    return false;
  }

  if (
    !data.steps ||
    data.steps.length < 1 ||
    data.steps.filter((s) => s.eventName === "" || s.waitValue === "").length >
      0
  ) {
    return false;
  }
  return true;
};

function Form({ initialConfig, onSave, onDelete, isSaving }) {
  const titleRef = useRef();
  const [triggeredBy] = useState("segments");
  const [triggeredByValues, setTriggeredByValues] = useState([]);
  const [title, setTitle] = useState("");
  const [steps, setSteps] = useState([]);
  const sequenceId = initialConfig?.id;
  const isNewSequence = !sequenceId;
  const [isEditingTitle, setIsEditingTitle] = useState(isNewSequence);
  const [isValid, setIsValid] = useState(false);

  // On first load we prepopulate the form
  useEffect(() => {
    setTitle(initialConfig?.title);
    setSteps(initialConfig?.steps);
    // setTriggeredBy(initialConfig?.triggeredBy);
    setTriggeredByValues(initialConfig?.triggeredByValues);
    if (initialConfig) setIsValid(isValidData(initialConfig));
  }, [initialConfig]);

  useEffect(() => {
    const newValue = isValidData({
      title,
      steps,
      triggeredBy,
      triggeredByValues,
    });

    setIsValid(newValue);
  }, [steps, title, triggeredBy, triggeredByValues]);

  const handleOnAddFirstStep = (id) => {
    const newSteps = [
      {
        id: v4(),
        eventName: "",
        waitValue: "",
        waitUnit: "",
      },
    ];
    setSteps(newSteps);
  };

  const handleOnAddStep = (id) => {
    const newSteps = [...steps];
    if (newSteps.length >= MAX_STEPS) {
      return;
    }
    const blankStep = {
      id: v4(),
      eventName: "",
      waitValue: "",
      waitUnit: "",
    };
    const stepIndex = newSteps.findIndex((o) => o.id === id);
    newSteps.splice(stepIndex + 1, 0, blankStep);
    setSteps(newSteps);
  };

  const handleOnDuplicateStep = (id) => {
    const newSteps = [...steps];
    const stepIndex = newSteps.findIndex((o) => o.id === id);
    const duplicatedStep = { ...steps[stepIndex] };
    duplicatedStep.id = v4();
    newSteps.splice(stepIndex + 1, 0, duplicatedStep);

    setSteps(newSteps);
  };

  const handleOnDeleteStep = (id) => {
    const newSteps = [...steps];
    const stepIndex = newSteps.findIndex((o) => o.id === id);
    newSteps.splice(stepIndex, 1);
    setSteps(newSteps);
  };

  const handleOnEventNameChanged = (id, value) => {
    const newSteps = [...steps].map((step) => {
      if (step.id === id) {
        step.eventName = value;
      }
      return step;
    });
    setSteps(newSteps);
  };

  const handleOnWaitValueChanged = (id, value) => {
    const newSteps = [...steps].map((step) => {
      if (step.id === id) {
        step.waitValue = value;
      }
      return step;
    });
    setSteps(newSteps);
  };

  const handleOnWaitUnitChanged = (id, value) => {
    const newSteps = [...steps].map((step) => {
      if (step.id === id) {
        step.waitUnit = value;
      }
      return step;
    });
    setSteps(newSteps);
  };

  const handleOnSaveClick = (event) => {
    onSave({
      id: sequenceId || v4(),
      title,
      steps,
      triggeredBy,
      triggeredByValues,
      disabled: initialConfig.disabled,
    });
  };

  const handleOnDeleteClick = (event) => {
    onDelete(sequenceId);
  };

  useEffect(() => {
    if (isEditingTitle && titleRef.current) {
      titleRef.current.focus();
    }
  }, [isEditingTitle, titleRef]);

  return (
    <div className="flex-1 h-full flex flex-col justify-between">
      <div className="flex-1 overflow-y-auto p-4 ">
        <div className="max-w-2xl mx-auto py-8">
          <div className="flex space-x-4 mb-4 items-end">
            <div className="relative rounded-md shadow-sm w-full max-w-sm">
              <label className="mb-1 block" htmlFor="event">
                Sequence title
              </label>
              <input
                ref={titleRef}
                type="text"
                name="event"
                className="form-input block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm placeholder-slate-300"
                placeholder="e.g. Onboarding Users"
                value={title ? title : ""}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                onBlur={(event) => {
                  setIsEditingTitle(false);
                }}
              />
            </div>
          </div>

          {title && (
            <div className="flex">
              <div
                className={`mt-6 ${steps?.length > 0 ? "step-progress" : ""}`}
              >
                <CheckCircleIcon className={`w-6 h-6 text-indigo-400`} />
              </div>
              <div className="pl-2 w-full">
                <div className="flex justify-between">
                  <Panel>
                    <TriggeredByInput
                      value={
                        triggeredByValues ? triggeredByValues.join(",") : ""
                      }
                      onChange={(event) => {
                        setTriggeredByValues(event.target.value?.split(","));
                      }}
                    />
                  </Panel>
                </div>
                {(!steps || steps.length < 1) && (
                  <div className="h-12 group">
                    <div className="flex justify-center">
                      <button
                        disabled={
                          !triggeredByValues ||
                          triggeredByValues[0]?.trim() === ""
                        }
                        onClick={() => {
                          handleOnAddFirstStep();
                        }}
                        className="text-white disabled:cursor-not-allowed disabled:bg-slate-300 transition text-xs font-semibold bg-slate-800 hover:bg-slate-700 flex space-x-2 justify-center rounded items-center py-3 px-4"
                      >
                        <PlusCircleIcon className="w-4 h-4 " />{" "}
                        <span>Add a step</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {steps?.map((step, index) => (
            <Step
              key={step.id}
              id={step.id}
              number={index + 1}
              waitValue={step.waitValue}
              waitUnit={step.waitUnit}
              eventName={step.eventName}
              onEventNameChanged={handleOnEventNameChanged}
              onWaitValueChanged={handleOnWaitValueChanged}
              onWaitUnitChanged={handleOnWaitUnitChanged}
              onAddStep={handleOnAddStep}
              onDuplicateStep={handleOnDuplicateStep}
              onDeleteStep={handleOnDeleteStep}
              isLast={index === steps.length - 1}
              nextStepAvailable={index < MAX_STEPS - 1}
              duplicateAvailable={steps.length < MAX_STEPS}
            />
          ))}
        </div>
      </div>
      <footer className="p-2 border-t border-slate-200 bg-white flex justify-between">
        <div>
          {!isNewSequence && (
            <button
              onClick={handleOnDeleteClick}
              disabled={isSaving}
              className="text-red-500 text-xs font-semibold transition-colors bg-white hover:bg-red-50 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400 flex space-x-2 justify-center rounded items-center py-3 px-4 w-full"
            >
              <TrashIcon className="w-4 h-4" />
              <span>Delete Drip Sequence</span>
            </button>
          )}
        </div>
        {!isValid && (
          <p className="font-semibold text-xs text-red-400 pt-2">
            You must enter values for all inputs and not exceed the maximum of (
            {MAX_STEPS}) steps
          </p>
        )}
        <div>
          <button
            onClick={handleOnSaveClick}
            disabled={isSaving || !isValid}
            className={`text-white text-xs font-semibold transition-colors ${
              !isValid ? "disabled:cursor-not-allowed" : ""
            } bg-green-600 hover:bg-green-700 disabled:bg-green-800 flex space-x-2 justify-center rounded items-center py-3 px-4 w-full`}
          >
            {isSaving ? <Loader /> : <CheckCircleIcon className="w-4 h-4" />}{" "}
            <span>Save Drip Sequence</span>
          </button>
        </div>
      </footer>
    </div>
  );
}

export default Form;
